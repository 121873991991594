.App {
    text-align: center;
  }
  
  html,
  body {
    height: 100%;
  }
  
  .Layout {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a.effect-underline:after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 1em;
    width: 100%;
    border-bottom: 1px solid;
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
  }

  a.effect-underline:hover:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  h3 a {
  text-decoration: none;
  display: inline-block;
  position: relative;
  }

  @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@800&display=swap');

  .Title {
    box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 1em;
  margin-bottom: 2em;
  background-color: rgb(17, 55, 155);
  color: #fff;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }

  button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    all: unset;
  }